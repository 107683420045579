















import Vue from 'vue'

export default Vue.extend({
  name: 'DatabaseHelp',
  data: () => ({}),
  computed: {},
  watch: {},
  mounted() {},
  destroyed() {},
  methods: {},
})
