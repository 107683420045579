














import Vue from "vue";

export default Vue.extend({
  name: "ColorPicker",
  props: {
    title: String,
    defaultColor: String
  },
  data: () => ({
    color: ""
  }),
  computed: {
  },
  mounted() {
    this.color = this.defaultColor;
  },
  methods: {
    updateColor() {
      this.$emit("update-color", this.color);
    }
  },
});
