




















































import Vue from 'vue'
import ThemePicker from '@/components/manage/ThemePicker.vue'
import BuildDatabase from '@/components/manage/BuildDatabase.vue'
import BuildExploreInstitutions from '@/components/manage/BuildExploreInstitutions.vue'
import BuildExplorePanels from '@/components/explore/BuildExplorePanels.vue'
import { TranslateResult } from 'vue-i18n'
import MainContentTemplate from '@/components/MainContentTemplate.vue'
import GdprInfo from '@/components/GdprInfo.vue'
import { VuetifyThemeItem } from 'vuetify/types/services/theme'
import { GITHUB_REPO } from '@/utils/apis'

export default Vue.extend({
  name: 'Utils',

  components: {
    BuildExplorePanels,
    ThemePicker,
    BuildDatabase,
    BuildExploreInstitutions,
    MainContentTemplate,
    GdprInfo,
  },
  data: () => ({
    tabs: ['panels', 'institutions', 'database', 'theme', 'cookies'],
    githubRepo: GITHUB_REPO,
  }),
  computed: {
    tab: {
      set(tab: string) {
        const item = '0'
        this.$router.replace({ params: { ...this.$route.params, tab, item } })
      },
      get(): string | (string | null)[] {
        return this.$route.params.tab ? this.$route.params.tab : 'panels'
      },
    },
    background(): VuetifyThemeItem {
      return this.$vuetify.theme.themes.light.background
    },
    toolbarTitle(): TranslateResult {
      return this.$t('navigation.utils.toolbar.text')
    },
  },
  methods: {},
  mounted() {
    document.title = 'GTI ' + this.$t('navigation.utils.title.text')
  },
  watch: {},
})
