
































































































































import Vue from 'vue'
import ColorPicker from '@/components/ColorPicker.vue'
import { lightThemes } from '@/plugins/vuetify'
import { mapGetters, mapActions } from 'vuex'
import MainContentTemplate from '@/components/MainContentTemplate.vue'
import { GeneState } from '@/types/ui-types'
import GeneEntryTitle from '@/components/GeneEntryTitle.vue'

export default Vue.extend({
  name: 'ThemePicker',
  components: { ColorPicker, MainContentTemplate, GeneEntryTitle },
  data: () => ({
    themeSelected: -1,
    themes: lightThemes,
    hueRotation: 0,
    saturation: 1,
    geneState: GeneState,
  }),
  computed: {
    theme() {
      if (this.$vuetify.theme.dark) {
        return 'dark'
      } else {
        return 'light'
      }
    },
    ...mapGetters({
      chipOutlined: 'getChipOutlined',
    }),
  },
  mounted() {
    this.changeTheme()
  },
  methods: {
    ...mapActions(['updateChipOutlined']),
    getPrimary() {
      return this.$vuetify.theme.themes[this.theme].primary
    },
    getSecondary() {
      return this.$vuetify.theme.themes[this.theme].secondary
    },
    updatePrimary(color: string) {
      if (this.isValidColor(color)) {
        this.$vuetify.theme.themes[this.theme].primary = color
      }
    },
    updateSecondary(color: string) {
      if (this.isValidColor(color)) {
        this.$vuetify.theme.themes[this.theme].secondary = color
      }
    },
    updateHueRotation(hueRotation: number) {
      this.hueRotation = hueRotation
      this.$vuetify.theme.themes[this.theme].hueRotation = hueRotation
    },
    updateSaturation(saturation: number) {
      this.saturation = saturation
      this.$vuetify.theme.themes[this.theme].saturation = saturation
    },
    formatThemeResult() {
      var json = {
        primary: this.getPrimary(),
        secondary: this.getSecondary(),
        hueRotation: this.hueRotation,
        saturation: this.saturation,
      }
      return JSON.stringify(json, null, 2)
    },
    isValidColor(color: string) {
      return color != '#FF0000FF'
    },
    changeTheme() {
      if (this.themeSelected > -1) {
        this.updatePrimary(this.themes[this.themeSelected - 1].primary)
        this.updateSecondary(this.themes[this.themeSelected - 1].secondary)
        this.updateHueRotation(this.themes[this.themeSelected - 1].hueRotation)
        this.updateSaturation(this.themes[this.themeSelected - 1].saturation)
      }
    },
    getThemeLabel(n: number) {
      return this.$t('themePicker.button.theme.text') + ' #' + n
    },
  },
})
